import React, { Component } from 'react';

const initData = {
    heading: "sApp is available for all devices",
    headingText: "sApp is available for all devices, consectetur adipisicing elit. Itaque at harum quam explicabo. Aliquam optio, delectus, dolorem quod neque eos totam. Delectus quae animi tenetur voluptates doloribus commodi dicta modi aliquid deserunt, quis maiores nesciunt autem, aperiam natus.",
    headingTexttwo: "sApp is available for all devices, consectetur adipisicing elit. Vel neque, cumque. Temporibus eligendi veniam, necessitatibus aut id labore nisi quisquam.",
    headingSlug: "* Available on iPhone, iPad and all Android devices",
    playImg: "/img/google-play.png",
    appleImg: "/img/app-store.png",
}

class Download extends Component {
    state = {
        initData: {}
    }
    componentDidMount(){
        this.setState({
            initData: initData
        })
    }
    render() {
        return (
            <section className="section download-area overlay-dark ptb_100">
                <div className="container">
                    <div className="row justify-content-center">
                    <div className="col-12 col-md-10 col-lg-6">
                        {/* Download Text */}
                        <div className="download-text ">
                            <h2 className="pb-4 pb-sm-0">הירשמו עכשיו ותהנו מ:</h2>
                            <ul className="check-list">
                                <li>
                                    <div className={'list-box media'}>
                                        <span className="icon align-self-center"><i className={'fas fa-check'} /></span>
                                        <span>התפתחות אישית והגשמה</span>
                                    </div>
                                </li>
                                <li>
                                    <div className={'list-box media'}>
                                        <span className="icon align-self-center"><i className={'fas fa-check'} /></span>
                                        <span>התגברות על פחדים</span>
                                    </div>
                                </li>
                                <li>
                                    <div className={'list-box media'}>
                                        <span className="icon align-self-center"><i className={'fas fa-check'} /></span>
                                        <span>שיפור הביטחון העצמי</span>
                                    </div>
                                </li>
                                <li>
                                    <div className={'list-box media'}>
                                        <span className="icon align-self-center"><i className={'fas fa-check'} /></span>
                                        <span>שיפור משמעותי במערכות היחסים בחייכם</span>
                                    </div>
                                </li>
                                <li>
                                    <div className={'list-box media'}>
                                        <span className="icon align-self-center"><i className={'fas fa-check'} /></span>
                                        <span>גיוס מוטיבציה לשינוי</span>
                                    </div>
                                </li>
                                <li>
                                    <div className={'list-box media'}>
                                        <span className="icon align-self-center"><i className={'fas fa-check'} /></span>
                                        <span>השפעה חיובית על הסביבה</span>
                                    </div>
                                </li>
                                <li>
                                    <div className={'list-box media'}>
                                        <span className="icon align-self-center"><i className={'fas fa-check'} /></span>
                                        <span>שינוי הרגלים</span>
                                    </div>
                                </li>
                                <li>
                                    <div className={'list-box media'}>
                                        <span className="icon align-self-center"><i className={'fas fa-check'} /></span>
                                        <span>קבלת כלים להתמודדות עם מצבי לחץ וכעס</span>
                                    </div>
                                </li>
                                <li>
                                    <div className={'list-box media'}>
                                        <span className="icon align-self-center"><i className={'fas fa-check'} /></span>
                                        <span>קבלת כלים לתקשורת ברורה ומעצימה</span>
                                    </div>
                                </li>
                                <li>
                                    <div className={'list-box media'}>
                                        <span className="icon align-self-center"><i className={'fas fa-check'} /></span>
                                        <span>הוספת כלים מקצועיים לקליניקה שלכם</span>
                                    </div>
                                </li>
                                <li>
                                    <div className={'list-box media'}>
                                        <span className="icon align-self-center"><i className={'fas fa-check'} /></span>
                                        <span>הבנת מקור המחסומים בחייכם ומציאת הדרך להסרתם</span>
                                    </div>
                                </li>
                            </ul>
                        <a className="btn sApp-btn scroll" href={'#contact'}>למימוש עצמי לחץ/י כאן</a>
                        </div>
                    </div>
                    </div>
                </div>
            </section>
        );
    }
}

export default Download;