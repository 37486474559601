import React, { Component } from 'react';

const data = [
    {
        id: "1",
        avatorImg: "/img/avatar-1.png",
        reviewer: "שרית נקרי יעקובי",
        address: "",
        text: "מכללה מדהימה עם מרצות נפלאות ומקסימות! נהניתי בכל שיעור, יצאתי עם המון כלים נפלאים לחיים ואני כבר מחכה לקורס הבא! תודה על כל הידע, הזמן והאהבה שלכם.",
        icon_1: "fas fa-star",
        icon_2: "fas fa-star",
        icon_3: "fas fa-star",
        icon_4: "fas fa-star",
        icon_5: "far fa-star"
    },
    {
        id: "2",
        avatorImg: "/img/avatar-2.png",
        reviewer: "גלית מיס",
        address: "",
        text: "אני אסירת תודה על התקופה המאתגרת בחיים שלי. בזמן קצר למדתי להציב לעצמי יעדים, ואכן כבשתי אותם. תודה לכם על המשאבים.",
        icon_1: "fas fa-star",
        icon_2: "fas fa-star",
        icon_3: "fas fa-star",
        icon_4: "fas fa-star",
        icon_5: "fas fa-star"
    },
    {
        id: "3",
        avatorImg: "/img/avatar-3.png",
        reviewer: "אורית בוכורוב",
        address: "",
        text: "מכללת דרך הערך זהו מקום שהוא בית חם לכל מי רוצה לעבור תהליך מרתק של שינוי ופיתוח תקשורת בסביבת אנשים מצויינים.",
        icon_1: "fas fa-star",
        icon_2: "fas fa-star",
        icon_3: "fas fa-star",
        icon_4: "fas fa-star",
        icon_5: "far fa-star"
    }
]

class ReviewSection extends Component {
    render() {
        return (
            <section className="section testimonial-area ptb_100">
                <div className="container text-center">
                    <h2>מה אומרים עלינו?</h2>
                    <div className="row justify-content-center align-items-center">

                    <div className="col-12 col-md-10 col-lg-8">
                        <div className="testimonials owl-carousel">
                        {/* Single Testimonial */}
                        {data.map((item, idx) => {
                            return(
                                <div key={`rt_${idx}`} className="single-testimonial p-3 p-md-5">
                                    {/* Client Description */}
                                    <div className="client-description">
                                    {/* Client Text */}
                                    <div className="client-text">
                                        <p>{item.text}</p>
                                    </div>
                                        {/* Client Name */}
                                        <h3 className="client-name mt-4 mb-2">{item.reviewer}</h3>
                                    </div>
                                </div>
                            );
                        })}
                        </div>
                    </div>
                    </div>
                </div>
            </section>
        );
    }
}

export default ReviewSection;