import React, { Component } from 'react';

const initData = {
    preHeading: "Premium",
    preHeadingspan: "Features",
    heading: "החזון שלנו הוא לאפשר לכמה שיותר אנשים ליצור את השינוי שהם רוצים בחייהם, ואף להרחיב את מעגל ההשפעה החיובית שלהם על סביבתם",
    headingText: "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Laborum obcaecati dignissimos quae quo ad iste ipsum officiis deleniti asperiores sit.",
    headingTexttwo: "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Laborum obcaecati."
}

const data = [
    {
        image: "fas fa-seedling",
        title: "התפתחות אישית והגשמה",
        content: "למידת דרכים אפקטיביות ומיידיות לשינוי הרגלים והשגת כל מטרה, תוך חיזוק הביטחון והערך העצמי."
    },
    {
        image: "fas fa-heartbeat",
        title: "בריאות פיזית ונפשית",
        content: "קבלת כלים להתמודדות עם מצבי לחץ וכעס, נטרול מחשבות שליליות, ניהול זמן נכון ורגוע."
    },
    {
        image: "fas fa-chess-knight",
        title: "שחרור מחסומים",
        content: "שינוי תוכנית הפעולה המוכרת של האדם, פריצת חסמים ותקיעויות."
    },
    {
        image: "fas fa-hand-holding-heart",
        title: "מערכות יחסים מיטביות",
        content: "מתן כלים לשיפור מערכות יחסים של האדם עם עצמו ועם כל סביבתו: בזוגיות, במשפחה, בעבודה."
    },

    {
        image: "fas fa-shapes",
        title: "התמודדות עם משברים וטראומות",
        content: "טכניקות יעילות להתמודדות עם משברי החיים ומציאת הנתיב הנכון להצלחה."
    },

    {
        image: "fas fa-stopwatch",
        title: "שינוי אמיתי בזמן קצר",
        content: "שינוי משמעותי באפיקי חיים רבים שנעשה במהירות יחסית לשיטות מסורתיות."
    },
]

class FeatureSection extends Component {
    state = {
        initData: {},
        data: []
    }
    componentDidMount(){
        this.setState({
            initData: initData,
            data: data
        })
    }
    render() {
        return (
            <section id="features" className="section features-area style-two overflow-hidden ptb_100">
                <div className="container">
                    <div className="row">
                    <div className="col-12 col-md-10 col-lg-8">
                        {/* Section Heading */}
                        <div className="section-heading">
                        <h2>{this.state.initData.heading}</h2>
                        </div>
                    </div>
                    </div>
                    <div className="row">
                        {this.state.data.map((item, idx) => {
                            return(
                                <div key={`ffd_${idx}`} className="col-12 col-md-6 col-lg-4 my-3 res-margin">
                                    {/* Image Box */}
                                    <div className="image-box text-center icon-1 p-5">
                                    {/* Featured Image */}
                                    <div className="featured-img mb-3">
                                        <i className={item.image} />
                                    </div>
                                    {/* Icon Text */}
                                    <div className="icon-text">
                                        <h3 className="mb-2">{item.title}</h3>
                                        <p>{item.content}</p>
                                    </div>
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                    <h2 className="text-center">את כל אלה ניתן להשיג אתנו בקורס NLP Practitioner אונליין!</h2>
                </div>
            </section>
        );
    }
}

export default FeatureSection;