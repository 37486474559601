import React, { Component } from 'react';
import axios from 'axios';

const BASE_URL = "https://my-json-server.typicode.com/themeland/sapp/themeOneWorkSection";

class Work extends Component {
    state = {
        data: {},
        workData: []
    }
    componentDidMount(){
        axios.get(`${BASE_URL}`)
            .then(res => {
                this.setState({
                    data: res.data,
                    workData: res.data.workData
                })
                // console.log(this.state.data)
            })
        .catch(err => console.log(err))
    }
    render() {
        return (
            <section className="section work-area bg-overlay overflow-hidden ptb_100">
                <div className="container">
                    <div className="row">
                        <div className={'col-sm-8'}>
                            <h2 className="text-white">למי מתאים הקורס?</h2>
                            <p><i className="fas fa-chevron-circle-left"></i> אם את/ה רוצה לשפר את היכולת שלך לתקשר באופן יעיל יותר</p>
                            <p><i className="fas fa-chevron-circle-left"></i> אם את/ה מעונין/ת לשלוט טוב יותר על המחשבות ועל מצב הרוח שלך</p>
                            <p><i className="fas fa-chevron-circle-left"></i> אם את/ה מרגיש/ה שהגיע הזמן לשיפור משמעותי בכל תחומי החיים</p>
                            <p><i className="fas fa-chevron-circle-left"></i> אם יש לך קושי או תקיעות כלשהי במערכות היחסים</p>
                            <p><i className="fas fa-chevron-circle-left"></i> אם את/ה רוצה כלים מעשיים להגשת מטרות ויעדים בחיים האישיים והעסקיים</p>
                            <p><i className="fas fa-chevron-circle-left"></i> אם את/ה חפץ להעשיר את הידע שלך כהורה, כמטפל/ת, מחנך/ת או כל מקצוע מול קהל</p>
                            <h3 className={'h2'}>הקורס הזה הוא בשבילך! <a href={'#contact'} className={'btn sApp-btn scroll'}>לחץ/י כאן להרשמה!</a></h3>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}

export default Work;