import React, { Component } from 'react';
import axios from 'axios';

const BASE_URL = "https://my-json-server.typicode.com/themeland/json-server/themeOneTeamSection";

class Team extends Component {
    state = {
        data: {},
        teamData: [],
        teamIcons: []
    }
    componentDidMount(){
        axios.get(`${BASE_URL}`)
            .then(res => {
                this.setState({
                    data: res.data,
                    teamData: res.data.teamData,
                    teamIcons: res.data.teamIcons
                })
                // console.log(this.state.data)
            })
        .catch(err => console.log(err))
    }
    render() {
        return (
            <section className="section team-area team-style-two overflow-hidden ptb_100">
                <div className="container">
                    <div className="row justify-content-center">
                    <div className="col-12 col-md-10 col-lg-6">
                        {/* Section Heading */}
                        <div className="section-heading text-center">
                            <h2 className="text-capitalize">על המכללה</h2>
                            <p className="d-none d-sm-block mt-4">מכללה ללימודי NLP בהסמכה בינלאומית מטעם בית-הספר העולמי HNLP</p>
                        </div>
                    </div>
                    </div>
                    <div className="row the-content">
                        <div className="col-sm-2"></div>
                        <div className="col-sm-8">
                            <div className={'row'}>
                                {window.innerWidth > 768 &&
                                <div className={'col-sm-5'}>
                                    <img src={'img/shirly-3.jpg'} alt="" className={'radius-100'} />
                                </div>
                                }
                                <div className={'col-sm-7'}>
                                    <p>מכללת ״דרך הערך״ הוקמה ע"י שירלי כראדי, NLP Master Practitioner & Trainer, בעלת קליניקה פרטית וניסיון מעשי עשיר בתחום האימון והטיפול, כמו גם בתחום הניהול ועולם העסקים.</p>
                                    <p>לאחר שנים בהם עסקה בטיפול ואימון אישי, ולאחר שנוכחה לתוצאות המדהימות של השימוש בכלי ה-NLP בהיבטים שונים בחייה ובחיי מטופליה, הקימה את מכללת "דרך הערך" ללימודי NLP, זאת במטרה לתת לכל אדם החפץ בכך הזדמנות ללמוד איך לשפר משמעותית את חייו</p>
                                    <p>במסגרת הלימודים במכללה, מונגשים הכלים החשובים והפרקטיים של ה-NLP, להפקת מירב התועלת בכל תחום בחיים, על מנת לחיות חיים טובים ומספקים יותר.</p>
                                    <p>היום שירלי היא המאמנת הבכירה במכללת "דרך הערך".</p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className={'section-heading text-center'}>
                        <h2 className={'h2 text-center'}>המרצות שלנו</h2>
                       <p className={'d-none d-sm-block mt-4'}>לצדה של שירלי כראדי צוות מרצות מהשורה הראשונה בארץ:</p>
                    </div>

                    <div className="row justify-content-center">
                        <div className="col-10">

                            <div className={'team-carousel owl-carousel'}>

                                <div className="single-team text-center radius-100 overflow-hidden m-2 m-lg-0">
                                    <div className="team-thumb radius-100 d-inline-block position-relative overflow-hidden">
                                        <img src={'/img/dorit.jpg'} alt="" />
                                        <h4 className="team-name">דורית רייף</h4>
                                        <div className="team-overlay radius-100">
                                            <p className="team-post text-white mt-2 mb-3">מרצה, מאמנת ויועצת עסקית, NLP Master Trainer.</p>
                                            <p className="team-post text-white mt-2 mb-3">בעלת ניסיון של למעלה מ-10 שנים באימון אישי, קריירה ופיננסי. מומחית לקידום עסקים ולהעצמה אישית וכן יזמת ויוצרת"freely" - תרגומון רגשות ולוח ניהול זמן הנמכרים ברשתות המובילות.</p>
                                        </div>
                                    </div>
                                </div>

                                <div className="single-team text-center radius-100 overflow-hidden m-2 m-lg-0">
                                    <div className="team-thumb radius-100 d-inline-block position-relative overflow-hidden">
                                        <img src={'/img/lecture-1.jpg'} alt="מרי קסלר לופו" />
                                        <h4 className="team-name">מרי קסלר לופו</h4>
                                        <div className="team-overlay radius-100">

                                            <p className="team-post text-white mt-2 mb-3">יועצת מוסמכת בהתפתחות קריירה ופיתוח עסקי, פסיכותרפיסטית גופנית, NLP Master Trainer, יועצת SFM (מידול מפתחות ההצלחה) ומנחת קבוצות.</p>
                                            <p className="team-post text-white mt-2 mb-3">בעלת ניסיון של למעלה מ- 15 שנים בתחום ההתפתחות אישית במסגרות ארגוניות ופרטיות.</p>
                                        </div>
                                    </div>
                                </div>

                                <div className="single-team text-center radius-100 overflow-hidden m-2 m-lg-0">
                                    <div className="team-thumb radius-100 d-inline-block position-relative overflow-hidden">
                                        <img src={'/img/silvi.jpg'} alt="" />
                                        <h4 className="team-name">סילבי אלישקוב</h4>
                                        <div className="team-overlay radius-100">

                                            <p className="team-post text-white mt-2 mb-3">תואר שני בייעוץ עם התמחות בהתפתחות הילד. מרצה ומנחת קבוצות, מאסטר וטריינר  NLP ומטפלת רגשית לילדים, נוער ומבוגרים.  ניסיון של מעל ל-12 שנים בתהליכי העצמה, התפתחות אישית וטיפול רגשי לילדים נוער, ומבוגרים והדרכת הורים.</p>
                                        </div>
                                    </div>
                                </div>

                                <div className="single-team text-center radius-100 overflow-hidden m-2 m-lg-0">
                                    <div className="team-thumb radius-100 d-inline-block position-relative overflow-hidden">
                                        <img src={'/img/dafna.jpg'} alt="" />
                                        <h4 className="team-name">דפנה ממן</h4>
                                        <div className="team-overlay radius-100">

                                            <p className="team-post text-white mt-2 mb-3">מטפלת ומנחת סדנאות. עובדת סוציאלית ו – NLP trainer. מייסדת מכללה ללימודי NLP באשקלון.</p>
                                            <p className="team-post text-white mt-2 mb-3">בעלת ניסיון של למעלה מ – 25 שנים בפסיכותרפיה, אימון והדרכת הורים.</p>
                                        </div>
                                    </div>
                                </div>

                                <div className="single-team text-center radius-100 overflow-hidden m-2 m-lg-0">
                                    <div className="team-thumb radius-100 d-inline-block position-relative overflow-hidden">
                                        <img src={'/img/yudit.jpg'} alt="" />
                                        <h4 className="team-name">יהודית נבעה</h4>
                                        <div className="team-overlay radius-100">

                                            <p className="team-post text-white mt-2 mb-3">מרצה ומנחת קבוצות, NLP Trainer, Focusing Professional מטעם המכון הבינלאומי להתמקדות בניו יורק, מנחה בכירה בדמיון מודרך.</p>
                                            <p className="team-post text-white mt-2 mb-3">בעלת ניסיון של כ-20 שנים בליווי תהליכי העצמה אישיים וקבוצתיים.</p>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>

                </div>
            </section>
        );
    }
}

export default Team;