import React, { Component } from "react";


class ContactForm extends Component {

  state = {
    name: '',
    email: '',
    subject: '',
    message: ''
  }

  changHandler = (event) => {
    this.setState({
      [event.target.name]: event.target.value
    })
  }

  render() {
    return (
      <div className="contact-box text-center">
        <form className="contact-form" method={'post'} action={'http://nlp-college.co.il/thankyou/'}> {/* https://clients.frontask.co.il/25345/Pages/Utilities/WebToLead.aspx?type=form */}
          <input type="hidden" name="lp1-form" value="עמוד נחיתה קורס פרקטישינר - טופס תחתון" />
          <div className="row">
            <div className="col-12">
              <div className="form-group">
                <input type="text" className="form-control" name="FirstName" placeholder="שם מלא" required="required" />
              </div>
              <div className="form-group">
                <input type="text" className="form-control" name="Phone" placeholder="טלפון" required="required" />
              </div>
              <div className="form-group">
                <input type="email" className="form-control" name="Email" placeholder="דואר אלקטרוני" />
              </div>
            </div>
            <div className="col-12">
                <button
                    type="submit"
                    className="btn btn-lg btn-block mt-3"><span className="text-white pr-3"><i className="fas fa-paper-plane" /></span>
                    שליחת פרטים
                </button>
            </div>
          </div>
        </form>
      </div>
    );
  }
}

export default ContactForm;