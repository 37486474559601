import React, { Component } from 'react';

const initData = {
    heading: "הצעד הראשון למימוש עצמי מתחיל כאן.",
    content: "זה הזמן להחשף לשיטות עוצמתיות ולהתקדם לשלב של אושר והצלחה בחיים באמצעות ",
    btnText: "ליעוץ חינם",
    formHeading: "לשיחת יעוץ נא למלא את הפרטים:",
    formText: "יש לך שאלות בנוגע לקורס? מתלבט/ת? רוצה להירשם? מלא/י את הטופס שלהלן ונציג מטעמנו יצור עמך קשר במהרה.",
    formBtn: "שליחת פרטים",
}

class HeroSection extends Component {
    state = {
        data: {}
    }
    componentDidMount(){
        this.setState({
            data: initData
        })
    }
    render() {
        return (
            <section id="home" className="section welcome-area bg-overlay d-flex align-items-center">
                <div className="container">
                    <div className="row align-items-center justify-content-center">
                    {/* Welcome Intro Start */}
                    <div className="col-12 col-lg-7">
                        <div className="welcome-intro">
                        <h1 className="text-white">{this.state.data.heading}</h1>
                        <p className="text-white my-4">{this.state.data.content}<a className={'cnr-badge-white scroll'} href="#features">קורס NLP Practitioner אונליין</a></p>
                        {/* Store Buttons
                        <div className="button-group store-buttons d-flex">
                            <a href="/#" className="btn sApp-btn text-uppercase">{this.state.data.btnText}</a>
                        </div>
                        */}
                        </div>
                        {window.innerWidth <= 768 &&
                        <div className="mob-scroll-down">
                            <a href="#features" aria-label="גלילה למקטע החשוב הבא" className="scroll">
                                <span className="txt">ספר לי עוד!</span>
                                <i className="fas fa-chevron-down"></i>
                            </a>
                        </div>
                        }
                    </div>
                    <div className="col-12 col-md-8 col-lg-5">
                        {/* Contact Box */}
                        <div className="contact-box bg-white text-center rounded p-4 p-sm-5 mt-5 mt-lg-0 shadow-lg">
                        {/* Contact Form */}
                        <form id="contact-form" method={'post'} action={'http://nlp-college.co.il/thankyou/'}> {/* https://clients.frontask.co.il/25345/Pages/Utilities/WebToLead.aspx?type=form */}
                            <input type="hidden" name="lp1-form" value={'עמוד נחיתה קורס פרקטישינר - טופס עליון | רוחב: ' + window.innerWidth} />
                        {/*}
                        <input type='hidden' name='ProcessDefinitionStepID' value='PP60400279_0B8F_41F8_ABCC_15834568F4BE' />
                            <input type='hidden' name='RedirectTo' value='http://nlp-college.co.il/thank-you/' />
                            <input name='enc' type='hidden' value='&#9760;' />
                            <input type='hidden' name='UpdateExistingDetails' value='true' />
                            <input type='hidden' name='SystemID' value='95b6cfb2-ca97-486a-82fb-e8c9868f4d66' />
                            */}

                            <div className="contact-top">
                            <h3 className="contact-title">{this.state.data.formHeading}</h3>
                            <h5 className="text-secondary fw-3 py-3">{this.state.data.formText}</h5>
                            </div>
                            <div className="row">
                            <div className="col-12">
                                <div className="form-group">
                                <input type="text" className="form-control" name="FirstName" placeholder="שם מלא" required="required" />
                                </div>
                                <div className="form-group">
                                    <input type="text" className="form-control" name="Phone" placeholder="טלפון" required="required" />
                                </div>
                                <div className="form-group">
                                <input type="email" className="form-control" name="Email" placeholder="דואר אלקטרוני" />
                                </div>

                            </div>
                            <div className="col-12">
                                <button className="btn btn-bordered w-100 mt-3 mt-sm-4" type="submit">{this.state.data.formBtn}</button>
                            </div>
                            </div>
                        </form>
                        <p className="form-message" />
                        </div>
                    </div>
                    </div>
                </div>
                {/* Shape Bottom */}
                <div className="shape-bottom">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1000 100" preserveAspectRatio="none">
                    <path className="shape-fill" fill="#FFFFFF" d="M421.9,6.5c22.6-2.5,51.5,0.4,75.5,5.3c23.6,4.9,70.9,23.5,100.5,35.7c75.8,32.2,133.7,44.5,192.6,49.7  c23.6,2.1,48.7,3.5,103.4-2.5c54.7-6,106.2-25.6,106.2-25.6V0H0v30.3c0,0,72,32.6,158.4,30.5c39.2-0.7,92.8-6.7,134-22.4  c21.2-8.1,52.2-18.2,79.7-24.2C399.3,7.9,411.6,7.5,421.9,6.5z" />
                    </svg>
                </div>
            </section>
        );
    }
}

export default HeroSection;