import React, { Component } from 'react';
import ContactForm from './ContactForm';

class ContactSection extends Component {
    state = {
        data: {},
        iconList: [
            {
                "id": 1,
                "iconClass": "fas fa-home",
                "text": "האודם 4, אשדוד"
            },
            {
                "id": 2,
                "iconClass": "fas fa-phone-alt",
                "text": "073-374-6068"
            },
        ]
    }
    render() {
        return (
            <section id="contact" className="contact-area bg-gray ptb_100">
                <div className="container">
                    <div className="row justify-content-center">
                    <div className="col-12 col-md-10 col-lg-6">
                        {/* Section Heading */}
                        <div className="section-heading text-center">
                        <h2 className="text-capitalize">הנתיב שלך למימוש עצמי מתחיל כאן</h2>
                        <p className="d-sm-block mt-4">לשיחת יעוץ ללא התחיבות כלל נא להשאיר פרטים בטופס שלהלן:</p>
                        </div>
                    </div>
                    </div>
                    <div className="row justify-content-center">
                    <div className="col-12 col-md-6 pt-4 pt-md-0">
                        <ContactForm />
                    </div>
                    </div>
                </div>
            </section>
        );
    }
}

export default ContactSection;