import React, { Component } from 'react';

class Header extends Component {
    render() {
        return (
            <header className="navbar navbar-expand-lg navbar-dark">
                <div className="container position-relative">
                    <a className="navbar-brand" href="/">
                        <img className="navbar-brand-regular" src={'/img/logo.png'} alt="brand-logo" />
                    </a>
                    {window.innerWidth > 768 &&
                    <p className={'top-heading'}>ללמוד NLP אונליין עם המנטוריות של מכללת דרך הערך! <a href={'tel:03'} className={'btn sApp-btn'}>לשיחת יעוץ לחץ/י כאן</a></p>

                    }
                </div>
        </header>
        );
    }
}

export default Header;