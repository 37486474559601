import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

import ThemeFive from "../themes/theme-five";

class MyRouts extends React.Component {
  render() {
    return (
      <div>
        <Router>
          <Switch>
            <Route exact path="/" component={ThemeFive} />
          </Switch>
        </Router>
      </div>
    );
  }
}
export default MyRouts;